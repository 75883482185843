import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[
          `how to blog`,
          `automated investment`,
          `developer`,
          `portfolio`,
          `machine learning`,
          `Data Science`,
          `Software developer`,
          `python`,
          `open source`,
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          {/*<h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            ¿Qué es la vida? Un frenesí 
          </h2>*/}
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            {/*<figcaption>Me smiling kindof</figcaption>*/}
          </figure>
          {/*<h2 id="dynamic-styles">Daniel Carlander. What does he know? Does he know things?? Let's find out</h2>*/}
          <p>
            Hello! I'm Daniel Carlander, an Applied Scientist at Amazon within
            the Alexa Entertainment team, specializing in Recommender Systems
            and Generative AI.
          </p>
          <p>
            At Amazon, I've led groundbreaking projects, including developing a
            runtime system for integrating Recommender systems for Music in
            Alexa, benefiting millions of users daily, and pioneering the
            LLM-based Alexa integrating APIs into extensive models using
            large-scale distributed training.
          </p>
          <p>
            Previously, at Ericsson and as a Research Scientist in Madrid, I've
            significantly enhanced network optimization and deep learning
            models.
          </p>
          <p>
            I have also worked as a freelance web designer with customers like{" "}
            <a href="https://www.emestudios.com/">EME</a>, and the{" "}
            <a href="https://www.csic.es/">CSIC</a>.
          </p>
          <p>
            I had the pleasure to work with the{" "}
            <a href="https://www.madrid.es/portal/site/ayto/">
              Ayuntamiento de Madrid (City of Madrid)
            </a>{" "}
            in the development of the{" "}
            <a href="https://diario.madrid.es/blog/notas-de-prensa/el-prototipo-cibeles-del-ayuntamiento-permite-acceder-a-informacion-urbanistica-las-24-horas-los-365-dias-del-ano/">
              Cibeles project
            </a>
            , an end to end voice assistant thanks to{" "}
            <a href="https://www.linkedin.com/in/natirodriguez/">
              Natalia Rodriguez
            </a>{" "}
            , founder of Saturno Labs and member of the Forbes 30 under 30.
          </p>
          <p>
            I hold a Masters Degree from the{" "}
            <a href="https://funginstitute.berkeley.edu/people/daniel-carlander/">
              University of California, Berkeley
            </a>{" "}
            and Bachelors degree in Telecommunications Engineering from the{" "}
            <a href="https://www.gsi.upm.es/en/investigacion?view=member&id=239&task=show">
              Universidad Politécnica de Madrid
            </a>
            .
          </p>
          <p>
            I some times write on{" "}
            <a href="https://medium.com/@danielcarlander">Medium</a> and I love
            learning languages. I'm fluent in English, Spanish, Swedish and
            Italian, as well as basic French.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "eme-horizontal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
